import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import SignupForm from '../features/authentification/signup/SignupForm';


const SignupPage = () => {
  return (
    <div>
      <SignupForm />
   </div>
  )
}

export default SignupPage