export const initialState = {
    userArray: {
        pid: null,
    // will put uid here for now, but not certain if this is secure
        name: null,
        email: null

    },
    utils : {
        authChange: {
            isLoading: false,
            errMsg: ""
        },
    },
    
    
}


// how do you structure loading data