import React from 'react'
import LoginForm from '../features/authentification/login/LoginForm';
import LogoutButton from '../features/authentification/logout/LogoutButton';

const LoginPage = () => {
  return (
    <div>
      <LoginForm />
   </div>
  )
}

export default LoginPage